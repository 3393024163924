header[class*='header'] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
      270deg,
      #56a7f2 27.97%,
      rgba(255, 255, 255, 0) 83.44%
    ),
    #2e52f8;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.15);
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: center;
}
